export const environment = {
  appVersion: require('../../package.json').version,
  env: ' -- sandbox',
  themeBaseUrl: '',
  production: false,
  baseUrl: 'https://so-api.smartopstest.angeiontestsite.com/api/',
  baseUrlDashboard: 'https://so-api.smartopstest.angeiontestsite.com/dashboard-api/dashboardApp',
  xApiKey: 'XndPw5jdcj8u33ydHQunE227YyXh6y0E1xmAGe8z',
  // formioUrl:'https://ihniktmcnwsxpbo.form.io/case1',
  formio: {
    baseUrl: 'https://claims-sandbox.formio.sandbox.angeiontestsite.com/',
    lookupFormName: 'servicefindorcreate',
    noValidate: 'LNR4wQUU8ZfpPe7He85kDdKgiWGSER',
  },
};
